import { Stack } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SamlAuthCallback: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Get the token from URL parameters
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');

        if (token) {
            // Store the saml token in local storage
            localStorage.setItem('samlToken', token);

            // If this is running in a popup window, notify the parent
            if (window.opener) {
                window.opener.postMessage('samlLoginSuccess', '*');
                window.close();
            } else {
                // If not in popup, redirect to dashboard
                navigate('/welcome');
            }
        } else {
            // Handle error case
            console.error('No token received from SAML authentication');
            navigate('/login');
        }
    }, [navigate]);

    return (
        <Stack
            styles={{
                root: {
                    height: '100vh',
                    width: '100vw',
                    justifyContent: 'center',
                    alignItems: 'center'
                }
            }}
        >
            Processing authentication...
        </Stack>
    );
};

export default SamlAuthCallback;
