/* eslint-disable @typescript-eslint/no-unused-vars */
import { AccountInfo } from '@azure/msal-browser';
import { ActionButton, Stack, Text } from '@fluentui/react';
import { idpService } from 'services/api/identity-provider.service';
import React, { useState } from 'react';

import AzureAuthenticationButtonDE from '../azure/azure-authentication-component-DE';
import AzureAuthenticationButtonEN from '../azure/azure-authentication-component-EN';

import campusIcon from 'media/img/campusIcon.png';
import campusLogo from 'media/img/ivcampus-logo.svg';
import ivicosBlack from 'media/img/ivicos-black.svg';
import mail from 'media/img/mail.svg';
import IvicosStrings from '../kits/language/stringKit';
import { useLoginClassNames } from '../styles/login.style';
import { useClassNames } from '../styles/responsiveness';
import { useLanguage } from '../components/context/LanguageContext';
import PasswordlessAccessModal from './PasswordlessAccessModal';

const Login: React.FC<{}> = () => {
    const providedEmail = sessionStorage.getItem('showEmail') === 'true' && sessionStorage.getItem('email') && sessionStorage.getItem('email');
    const useAlternativeEmailService = sessionStorage.getItem('useAlternativeEmailService')
        ? sessionStorage.getItem('useAlternativeEmailService') === 'true'
        : false;

    const [email, setEmail] = useState(providedEmail || '');
    const [, setCurrentUser] = useState<AccountInfo>();
    const [opacityVal, setOpacityVal] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [underlineEN, setUnderlineEN] = useState(false);
    const [underlineDE, setUnderlineDE] = useState(false);
    const [underlineClickToKnowMore, setUnderlineClickToKnowMore] = useState(false);
    const [boldEN, setBoldEN] = useState(false);
    const [boldDE, setBoldDE] = useState(false);
    const [openPasswordlessAccessModal, setOpenPasswordlessAccessModal] = useState(false);

    const { selectedLanguageOption, setSelectedLanguageOption }: any = useLanguage();

    const {
        loginPageAlignment,
        loginPageCampusLogo,
        loginPageCampusIcon,
        loginPageMiddleCard,
        loginPageInnerCard,
        loginPageSignIn,
        loginPageInputField,
        loginPageStackContainingMailAndSubmitButton,
        loginPageSubmitButton,
        loginPageSubmitButtonText,
        loginPageLoading
    } = useLoginClassNames();
    const { ivicosLogo } = useClassNames();

    const onSubmit = (event: any) => {
        event.preventDefault();

        if (email.trim().length > 0 && !showErrorMessage) {
            sessionStorage.setItem('email', email.trim());
            setShowLoading(true);
            sendMagicLink();
            sessionStorage.removeItem('useAlternativeEmailService');
            sessionStorage.removeItem('showEmail');
        } else {
            setShowErrorMessage(true);
        }
    };

    const sendMagicLink = () => {
        idpService
            .magicLink(email.trim(), selectedLanguageOption, useAlternativeEmailService)
            .then(() => {
                window.location.href = '/newUserAwaitingConfirmation';
            })
            .catch((error) => {
                window.location.href = '/newUserSomethingWrong';
            });
    };

    const onAuthenticated = async (userAccountInfo: AccountInfo) => {
        setCurrentUser(userAccountInfo);

        const token = sessionStorage.getItem('msToken');

        if (token != null) {
            idpService.authUserMS(token).then(() => {
                window.location.href = '/welcome';
            });
        } else {
            console.log('login error');
        }
    };

    const validateEmail = (emailValue: string) => {
        const emailRegex = /\S+@\S+\.\S+/;

        if (emailRegex.test(emailValue)) {
            setShowErrorMessage(false);
        } else {
            setShowErrorMessage(true);
        }
        if (emailValue === '') {
            setShowErrorMessage(true);
        }
    };

    return (
        <Stack>
            <Stack className={loginPageAlignment}>
                <Stack className={loginPageCampusLogo}>
                    <img src={campusLogo} alt="logo" />
                </Stack>

                <Stack className={loginPageCampusIcon}>
                    <img src={campusIcon} alt="campus icon" width={91.56} />
                </Stack>

                <Stack
                    style={{
                        height: selectedLanguageOption === 'en' ? 465 : 485,
                        width: 375,
                        backgroundColor: '#fff',
                        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
                    }}
                >
                    <Stack className={loginPageMiddleCard}>
                        {!showLoading ? (
                            <Stack className={loginPageInnerCard}>
                                <Text className={loginPageSignIn} variant="xxLarge">
                                    {IvicosStrings.signInTitle}
                                </Text>
                                <Text style={{ marginTop: 15 }} variant="small">
                                    {IvicosStrings.newToPassWordlessAcesss}{' '}
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            textDecoration: underlineClickToKnowMore ? 'underline' : 'none'
                                        }}
                                        onMouseEnter={() => {
                                            setUnderlineClickToKnowMore(true);
                                        }}
                                        onMouseLeave={() => {
                                            setUnderlineClickToKnowMore(false);
                                        }}
                                        onClick={() => setOpenPasswordlessAccessModal(true)}
                                    >
                                        {IvicosStrings.clickHereToKnowMore}
                                    </span>
                                </Text>
                                {openPasswordlessAccessModal && (
                                    <PasswordlessAccessModal
                                        openPasswordlessAccessModal={openPasswordlessAccessModal}
                                        setOpenPasswordlessAccessModal={setOpenPasswordlessAccessModal}
                                    />
                                )}

                                <form
                                    onSubmit={(event) => {
                                        onSubmit(event);
                                    }}
                                >
                                    <input
                                        type="text"
                                        id="email"
                                        placeholder={IvicosStrings.emailPlaceHolder}
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            validateEmail(e.target.value);
                                        }}
                                        className={loginPageInputField}
                                    />

                                    <Stack
                                        className={loginPageStackContainingMailAndSubmitButton}
                                        style={{
                                            opacity: opacityVal ? 0.7 : 1
                                        }}
                                    >
                                        <img src={mail} alt="" style={{ marginRight: 10 }} />
                                        <button
                                            type="submit"
                                            onMouseOver={() => {
                                                setOpacityVal(true);
                                            }}
                                            onMouseOut={() => {
                                                setOpacityVal(false);
                                            }}
                                            className={loginPageSubmitButton}
                                        >
                                            <Text className={loginPageSubmitButtonText} variant="large">
                                                {IvicosStrings.emailButtonText}
                                            </Text>
                                        </button>
                                    </Stack>
                                </form>

                                {showErrorMessage ? (
                                    <Text
                                        variant="small"
                                        style={{
                                            marginTop: 20,
                                            color: 'red'
                                        }}
                                    >
                                        {IvicosStrings.emailErrorMessage}
                                    </Text>
                                ) : (
                                    <Text
                                        variant="small"
                                        style={{
                                            marginTop: 20
                                        }}
                                    >
                                        {IvicosStrings.loginPageDescription3}
                                    </Text>
                                )}

                                <Stack
                                    style={{
                                        marginTop: 20,
                                        flexDirection: 'row'
                                    }}
                                >
                                    {selectedLanguageOption === 'en' && <Text>_______________________</Text>}
                                    {selectedLanguageOption === 'de' && <Text>______________________</Text>}

                                    <Text style={{ marginLeft: 19, marginRight: 19, marginTop: 5 }}>{IvicosStrings.orOptionTitle}</Text>
                                    {selectedLanguageOption === 'en' && <Text>_______________________</Text>}
                                    {selectedLanguageOption === 'de' && <Text>______________________</Text>}
                                </Stack>
                                <Stack
                                    style={{
                                        width: 315,
                                        height: 43,
                                        marginTop: 30,
                                        borderRadius: 5
                                    }}
                                >
                                    {selectedLanguageOption === 'en' && <AzureAuthenticationButtonEN onAuthenticated={onAuthenticated} type="submit" />}
                                    {selectedLanguageOption === 'de' && <AzureAuthenticationButtonDE onAuthenticated={onAuthenticated} type="submit" />}
                                </Stack>
                            </Stack>
                        ) : (
                            <Text variant="xxLarge" className={loginPageLoading}>
                                {IvicosStrings.loading}
                            </Text>
                        )}
                    </Stack>
                </Stack>

                <Stack
                    style={{
                        height: 40,
                        width: 375,
                        marginTop: 50,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Text variant="large">
                        <ActionButton
                            className="pressable--transparent language-login-button"
                            styles={{
                                root: {
                                    textDecoration: underlineEN ? 'underline' : 'none',
                                    fontSize: 18,
                                    fontWeight: boldEN || selectedLanguageOption === 'en' ? 'bold' : 'normal',
                                    cursor: 'pointer',
                                    padding: 0,
                                    margin: 0
                                }
                            }}
                            onMouseEnter={() => {
                                setUnderlineEN(true);
                            }}
                            onMouseLeave={() => {
                                setUnderlineEN(false);
                            }}
                            onClick={() => {
                                setSelectedLanguageOption('en');
                                IvicosStrings.setLanguage('en');
                                localStorage.setItem('UserLanguageChoice', 'en');
                                setBoldEN(true);
                                setBoldDE(false);
                            }}
                        >
                            English
                        </ActionButton>
                        |
                        <ActionButton
                            className="pressable--transparent language-login-button"
                            styles={{
                                root: {
                                    textDecoration: underlineDE ? 'underline' : 'none',
                                    fontSize: 18,
                                    fontWeight: boldDE || selectedLanguageOption === 'de' ? 'bold' : 'normal',
                                    cursor: 'pointer',
                                    padding: 0,
                                    margin: 0
                                }
                            }}
                            onMouseEnter={() => {
                                setUnderlineDE(true);
                            }}
                            onMouseLeave={() => {
                                setUnderlineDE(false);
                            }}
                            onClick={() => {
                                setSelectedLanguageOption('de');
                                IvicosStrings.setLanguage('de');
                                localStorage.setItem('UserLanguageChoice', 'de');
                                setBoldDE(true);
                                setBoldEN(false);
                            }}
                        >
                            Deutsch
                        </ActionButton>
                    </Text>
                </Stack>
                <Stack
                    style={{
                        marginTop: 50,
                        marginBottom: 15
                    }}
                    className={ivicosLogo}
                >
                    <Stack
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: 5
                        }}
                    >
                        <Text
                            variant="small"
                            style={{
                                alignSelf: 'end',
                                fontWeight: 'bold',
                                marginRight: 2
                            }}
                        >
                            {IvicosStrings.developedByTitle1}
                        </Text>
                        <Stack>
                            <img src={ivicosBlack} alt="ivicos-black-logo" width={75} height={20} />
                        </Stack>
                    </Stack>
                    <Text
                        variant="smallPlus"
                        style={{
                            textAlign: 'center'
                        }}
                    >
                        {IvicosStrings.developedByTitle2}
                    </Text>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default Login;
