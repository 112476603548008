import IvicosStrings from 'kits/language/stringKit';
export interface IVisitorsInvalidInvitationState {
    notActiveMessageLine: string;
    // error: any;
}

export const useVisitorsInvalidInvitationState: (error: any) => IVisitorsInvalidInvitationState = (error: any) => {
    const userLanguageChoice = localStorage.getItem('chosenLanguage') || localStorage.getItem('UserLanguageChoice') || 'en';
    function prepareNotActiveString(rawMessage: string, itemsToReplace: any) {
        const regExpToReplace = new RegExp(Object.keys(itemsToReplace).join('|'), 'gi');
        const notActiveMessageResult = rawMessage.replace(regExpToReplace, (matched): any => {
            return itemsToReplace[matched];
        });
        return notActiveMessageResult;
    }

    const prepareNotActiveMessageLine2 = () => {
        // const { error } = props;
        const eventName = error.event_name as string;
        const type = error.type;
        const repeat = error.repeat;
        const fromDate = new Date(error.open_from * 1000);
        const toDate = new Date(error.open_to * 1000);

        const currentLocale = userLanguageChoice === 'de' ? 'de-DE' : 'en-US';

        if (type == 'single') {
            const options: Intl.DateTimeFormatOptions = {
                weekday: 'short',
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            };
            const fromFormatted = fromDate.toLocaleString(currentLocale, options);
            const toFormatted = toDate.toLocaleString(currentLocale, options);

            const itemsToReplace = {
                '{eventName}': eventName,
                '{completeDateFrom}': fromFormatted,
                '{completeDateTo}': toFormatted
            };
            const notActiveMessageSingleResult = prepareNotActiveString(IvicosStrings.notActiveMessageSingle, itemsToReplace);
            return notActiveMessageSingleResult;
        } else if (repeat == 'daily') {
            const itemsToReplace = {
                '{eventName}': eventName,
                '{timeFrom}': fromDate.toLocaleTimeString(currentLocale, { hour: '2-digit', minute: '2-digit' }),
                '{timeTo}': toDate.toLocaleTimeString(currentLocale, { hour: '2-digit', minute: '2-digit' })
            };
            const notActiveMessageDailyResult = prepareNotActiveString(IvicosStrings.notActiveMessageDaily, itemsToReplace);
            return notActiveMessageDailyResult;
        } else {
            const itemsToReplace = {
                '{eventName}': eventName,
                '{dayFrom}': fromDate.toLocaleDateString(currentLocale, { weekday: 'long' }),
                '{dayTo}': toDate.toLocaleDateString(currentLocale, { weekday: 'long' }),
                '{timeFrom}': fromDate.toLocaleTimeString(currentLocale, { hour: '2-digit', minute: '2-digit' }),
                '{timeTo}': toDate.toLocaleTimeString(currentLocale, { hour: '2-digit', minute: '2-digit' })
            };
            const notActiveMessageWeeklyResult = prepareNotActiveString(IvicosStrings.notActiveMessageWeekly, itemsToReplace);
            return notActiveMessageWeeklyResult;
        }
    };
    return {
        notActiveMessageLine: prepareNotActiveMessageLine2()
        // error
    };
};
