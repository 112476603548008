/* eslint-disable @typescript-eslint/no-unused-vars */
import AlertModal from 'components/AlertModals/AlertModal';
import IvicosStrings from 'kits/language/stringKit';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
    CLIENT_EMIT_USER_AT_VISITOR_ENTRANCE,
    CLIENT_EMIT_USER_JOINED,
    CLIENT_EMIT_USER_JOINED_AN_AREA,
    CLIENT_EMIT_USER_PROFILE,
    RECONNECT,
    RECONNECT_ATTEMPT,
    RECONNECT_ERROR,
    RECONNECT_FAILED
} from 'services/socket-connection/events';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { useSocket } from '../hooks';
import { SocketContextProvider, defaultSocketContextState, socketReducer } from './SocketContext';
import { useBoolean } from '@uifabric/react-hooks';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISocketProviderProps {
    children?: React.ReactNode;
}

const SocketProvider: React.FunctionComponent<ISocketProviderProps> = ({ children }) => {
    const [windowBlur, setWindowBlur] = useState(false);
    const token = localStorage.getItem('ivAccessToken');
    const [displayAlert, { toggle: toggleAlert }] = useBoolean(false);

    const webSocketBase = process.env.REACT_APP_WS_BASE;

    if (!token || !webSocketBase) return null;

    const socket = useSocket(webSocketBase, {
        extraHeaders: {
            Authorization: token
        },
        reconnectionAttempts: 5,
        // reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        autoConnect: false
    });
    const [SocketState, SocketDispatch] = useReducer(socketReducer, defaultSocketContextState);
    const localProfile = useLocalProfile();

    useEffect(() => {
        // connect to the websocket
        socket.connect();

        // save the socket in the context
        SocketDispatch({ type: 'UPDATE_SOCKET', payload: socket });

        // listen default connection event
        startListeners();

        return () => {
            socket.disconnect();
        };
    }, [socket, localProfile]);

    const startListeners = () => {
        socket.io.on(RECONNECT, (attempt: any) => {
            console.info(`Reconnected on attempt ${attempt}`);
            localProfile && sendUserInfoToSocketIO();
        });

        socket.io.on(RECONNECT_ATTEMPT, (attempt: any) => console.info(`Reconnection attempt ${attempt}`));
        socket.io.on(RECONNECT_ERROR, (error: any) => console.info(`Reconnection error ${error}`));
        socket.io.on(RECONNECT_FAILED, () => {
            console.info('Reconnection failure');

            toggleAlert();
        });
    };

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden' && windowBlur) {
                socket.disconnect(); // Disconnect the socket when visibility state is "hidden" and window is not in focus
            } else {
                socket.connect(); // Reconnect the socket when visibility state changes to "visible" or window is in focus
            }
        };

        const handleWindowBlur = () => {
            setWindowBlur(true);
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('blur', handleWindowBlur);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('blur', handleWindowBlur);
        };
    }, [socket]);

    const sendUserInfoToSocketIO = useCallback(() => {
        // console.log('sending user info to the server...🚀');

        const lastAreaId = localStorage.getItem('lastAreaId');
        if (!localProfile?.room?.includes('undefined')) {
            localProfile && localStorage.setItem('localID', localProfile.uid);
        }
        socket?.emit(CLIENT_EMIT_USER_PROFILE, { localProfile, lastAreaId });
    }, [localProfile, socket]);

    useEffect(() => {
        localProfile && sendUserInfoToSocketIO();
    }, [sendUserInfoToSocketIO]);

    return (
        <SocketContextProvider value={{ SocketState, SocketDispatch }}>
            {displayAlert && <AlertModal title={IvicosStrings.generalAlertTitle} subText={IvicosStrings.webSocketAlert} toggleAlert={toggleAlert} />}
            {children}
        </SocketContextProvider>
    );
};

export default SocketProvider;
