import { DefaultButton, Dialog, IButtonStyles, Pivot, PivotItem, PrimaryButton, Stack } from '@fluentui/react';
import { IOnboardingVideoPopupState } from 'features/OnboardingVideoPopup/OnboardingVideoPopup.state';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';

const OnboardingVideoPopupView: React.FC<IOnboardingVideoPopupState> = (props) => {
    const {
        isDialogOpen,
        onDialogDismiss,
        dialogTitle,
        onSkipIntroVideo,
        availableLanguages,
        onLanguageSelected,
        //! deactivated the tutorialConfirmation step
        // currentStep,
        // onStartTutorial,
        // onSkipTutorial,
        playVideo,
        pauseVideo,
        videoRef,
        isVideoPlaying,
        language
    } = props;

    const orangeButtonStyles: IButtonStyles = {
        root: {
            backgroundColor: '#FF8900',
            color: '#fff',
            border: 0
        },
        rootHovered: {
            backgroundColor: '#e17900',
            color: '#fff',
            boder: 0
        },
        rootPressed: {
            backgroundColor: '#cd6e00',
            color: '#fff',
            border: 0
        }
    };

    return (
        <Dialog
            isOpen={isDialogOpen}
            dialogContentProps={{ title: dialogTitle }}
            styles={{ main: { width: '640px !important', maxWidth: '90% !important' } }}
            modalProps={{ isBlocking: true }}
            onDismiss={() => onDialogDismiss()}
        >
            <>
                {language === 'en' ? (
                    <video
                        autoPlay={true}
                        ref={videoRef}
                        src="https://static-cdn.vercel.app/ivCAMPUS-Onboarding-EN-short.mp4"
                        controls
                        style={{ width: '100%', height: '100%', marginBottom: 16 }}
                    />
                ) : (
                    <video
                        autoPlay={true}
                        ref={videoRef}
                        src="https://static-cdn.vercel.app/ivCAMPUS-Onboarding-DE-short.mp4"
                        controls
                        style={{ width: '100%', height: '100%', marginBottom: 16 }}
                    />
                )}
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                    <Pivot onLinkClick={onLanguageSelected}>
                        {availableLanguages.map((lang) => (
                            <PivotItem headerText={lang.displayName} itemKey={lang.languageCode} key={lang.languageCode} />
                        ))}
                    </Pivot>
                    <Stack horizontal horizontalAlign="space-between" style={{ width: '33%' }}>
                        <PrimaryButton style={{ marginRight: '20px' }} onClick={() => onSkipIntroVideo()} text={IvicosStrings.skipActionTitle} />

                        {!isVideoPlaying ? (
                            <DefaultButton styles={orangeButtonStyles} text={IvicosStrings.playActionTitle} onClick={() => playVideo()} />
                        ) : (
                            <DefaultButton styles={orangeButtonStyles} text={IvicosStrings.pauseActionTitle} onClick={() => pauseVideo()} />
                        )}
                    </Stack>
                </Stack>
            </>

            {/* deactivated the tutorialConfirmation step */}
            {/* {currentStep == 'tutorialConfirmation' && (
                <>
                    <Text variant="large">{IvicosStrings.onboardingVideoTitle}</Text>
                    <Stack horizontal style={{ height: '60px', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        <DefaultButton onClick={() => onSkipTutorial()} style={{ marginRight: '20px' }}>
                            {IvicosStrings.skipActionTitleInCap}
                        </DefaultButton>
                        <DefaultButton onClick={() => onStartTutorial()} styles={orangeButtonStyles}>
                            {IvicosStrings.startButtonLabelInCap}
                        </DefaultButton>
                    </Stack>
                </>
            )} */}
        </Dialog>
    );
};

export default OnboardingVideoPopupView;
