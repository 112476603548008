/* eslint-disable @typescript-eslint/no-unused-vars */
import { DefaultButton, DefaultEffects, Dialog, DialogType, getTheme, ITheme, mergeStyles, Stack, Text } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { IC } from 'components/SVGIcon';
import { useOrganizationBranding } from 'features/Header/hooks';
import { ICallAction } from 'features/Rooms/RoomView/CallActions';
import SetTopicDialog from 'features/SetTopicDialog/SetTopicDialog';
import 'intro.js/introjs.css';
import { IRoomResource } from 'kits/apiKit3/legacy';
import { getTextColorForBackground } from 'kits/colorKit';
import { EditIcon, ThreeDots } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { useCurrentOrgId, useIsVisitorAtLandingPage } from 'shared-state/location/hook';
import 'styles/helpers.css';
import { ICallActionGroup } from './CallActionBar';
import CallActionButton from './Children/CallActionButton/CallActionButton';

export interface ICallActionBarViewProps {
    actions: Array<ICallActionGroup>;
    onActionPressed: (a: ICallAction) => void;
    visible?: boolean;
    theme: ITheme;
    currentRoomId?: string;
    currentRoom?: IRoomResource;
    hideDialog: boolean;
    toggleHideDialog: () => void;
    dialogContentProps: {
        type: DialogType;
        title: string;
        closeButtonAriaLabel: string;
    };
    setToggleSetTopicDialog: any;
    showTopic: boolean;
    setShowTopic: any;
    topic: string;
    showIconOnHover: boolean;
    setShowIconOnHover: React.Dispatch<React.SetStateAction<boolean>>;
    showCallOut: boolean;
    setShowCallOut: React.Dispatch<React.SetStateAction<boolean>>;
    topicVal: string;
    cameraIsMuted: boolean;
    micIsMuted: boolean;
    sharingIsOn: boolean;
    statsAreVisible: boolean;
    isLocalUserHandRaised: boolean;
    isChatVisible?: boolean;
}

const CallActionBarView: React.FC<ICallActionBarViewProps> = (props) => {
    const {
        theme,
        currentRoomId,
        currentRoom,
        hideDialog,
        toggleHideDialog,
        dialogContentProps,
        actions,
        onActionPressed,
        visible,
        setToggleSetTopicDialog,
        showTopic,
        setShowTopic,
        topic,
        topicVal,
        showIconOnHover,
        setShowIconOnHover,
        setShowCallOut,
        cameraIsMuted,
        micIsMuted,
        sharingIsOn,
        statsAreVisible,
        isLocalUserHandRaised,
        isChatVisible
    } = props;

    const actionBarStyles = mergeStyles({
        backgroundColor: theme.palette.neutralLighterAlt,
        transition: 'opacity 0.25s ease-in-out',
        width: 'auto',
        boxShadow: DefaultEffects.elevation16,
        padding: '8px 16px'
    });

    const { smallDevice, mediumDevice, smallLaptop } = useWindowWidth();
    const [isVisitorAtLandingPage] = useIsVisitorAtLandingPage();

    const [orgId] = useCurrentOrgId();
    const [branding] = useOrganizationBranding(orgId);
    const localProfile = useLocalProfile();
    const buttonId = useId('callout-button');

    const bgColor: string | undefined = branding?.orgColors[0];

    return (
        <React.Fragment>
            {hideDialog && (smallDevice || mediumDevice || smallLaptop) && (
                <Dialog hidden={false} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps}>
                    {actions.map((actionGroup) => {
                        if (actionGroup.actions.length == 0) return <React.Fragment key={actionGroup.key}></React.Fragment>;
                        return (
                            <React.Fragment key={actionGroup.key}>
                                <Stack horizontal={false} horizontalAlign={'start'}>
                                    {actionGroup.actions.map((action) => (
                                        <>
                                            <Stack
                                                horizontal
                                                verticalAlign={'center'}
                                                key={action.key}
                                                onClick={() => {
                                                    onActionPressed(action);
                                                    toggleHideDialog();
                                                }}
                                            >
                                                <CallActionButton
                                                    action={action}
                                                    onPressed={(a: ICallAction) => null}
                                                    micIsMuted={micIsMuted}
                                                    cameraIsMuted={cameraIsMuted}
                                                    sharingIsOn={sharingIsOn}
                                                    statsAreVisible={statsAreVisible}
                                                    isLocalUserHandRaised={isLocalUserHandRaised}
                                                    isChatVisible={isChatVisible}
                                                />
                                                <Text>{action.title}</Text>
                                            </Stack>
                                        </>
                                    ))}
                                </Stack>
                            </React.Fragment>
                        );
                    })}
                </Dialog>
            )}

            <Stack>
                <Stack className={actionBarStyles + (!visible ? ' reveal--on-hover' : '')} horizontal tokens={{ childrenGap: 8 }}>
                    <Stack grow horizontal verticalAlign="center" horizontalAlign={smallDevice || mediumDevice ? 'center' : 'start'}>
                        {currentRoomId?.includes('personal') ? (
                            <Text aria-label="room-title">{currentRoom?.attributes.displayName}</Text>
                        ) : currentRoomId?.includes('visitor') && isVisitorAtLandingPage ? (
                            ''
                        ) : currentRoomId?.includes('visitor') && !isVisitorAtLandingPage ? (
                            IvicosStrings.waitingAreaTitle
                        ) : !(currentRoomId?.includes('personal') && currentRoomId?.includes('visitor')) ? (
                            <Text
                                aria-label="room-title"
                                variant="medium"
                                style={{
                                    maxWidth: smallDevice || mediumDevice ? '100%' : 160,
                                    wordBreak: 'break-word'
                                }}
                            >
                                {currentRoom?.attributes.displayName}
                            </Text>
                        ) : (
                            'Video call'
                        )}

                        {/* VM-Set topic for larger laptop*/}
                        {!(smallDevice || mediumDevice || smallLaptop) &&
                            window.location.pathname.includes('rooms') &&
                            !window.location.pathname.includes('personal-') && (
                                <Stack style={{ margin: '0 10px 0 10px', padding: '2px 5px 2px 5px', minWidth: '10%', maxWidth: '520px' }}>
                                    {(showTopic || topic.length > 0) && (
                                        <Stack
                                            onMouseEnter={() => {
                                                if (!(localProfile?.type === 'visitor' || localProfile?.type === 'auto-confirmed-visitor')) {
                                                    setShowIconOnHover(true);
                                                    setTimeout(() => {
                                                        setShowCallOut(true);
                                                    }, 300);
                                                }
                                            }}
                                            onMouseLeave={() => {
                                                if (!(localProfile?.type === 'visitor' || localProfile?.type === 'auto-confirmed-visitor')) {
                                                    setShowIconOnHover(false);
                                                    setShowCallOut(false);
                                                }
                                            }}
                                        >
                                            <Stack
                                                horizontalAlign="center"
                                                verticalAlign="center"
                                                aria-label="topic-name-button"
                                                style={{
                                                    backgroundColor: `${branding?.orgColors[0]}`,
                                                    color: bgColor ? getTextColorForBackground(bgColor) : 'black',
                                                    borderRadius: '4px',
                                                    padding: 10,
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    if (!(localProfile?.type === 'visitor' || localProfile?.type === 'auto-confirmed-visitor')) {
                                                        setToggleSetTopicDialog(false);
                                                    }
                                                }}
                                            >
                                                <Stack horizontal style={{ width: '100%' }}>
                                                    {showIconOnHover && (
                                                        <IC variant="custom" size={15} iconColor={bgColor ? getTextColorForBackground(bgColor) : 'black'}>
                                                            <EditIcon />
                                                        </IC>
                                                    )}
                                                    <Text
                                                        variant="medium"
                                                        style={{
                                                            fontWeight: 'bold',
                                                            marginLeft: showIconOnHover ? 5 : 0,
                                                            color: 'inherit'
                                                        }}
                                                    >
                                                        {topic}
                                                    </Text>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    )}
                                </Stack>
                            )}
                    </Stack>

                    {smallDevice || mediumDevice ? (
                        <Stack style={{ cursor: smallLaptop ? 'pointer' : '' }}>
                            <IC variant={'custom'} size={22}>
                                <ThreeDots onClick={() => toggleHideDialog()} />
                            </IC>
                        </Stack>
                    ) : (
                        actions.map((actionGroup, actionGroupIndex) => {
                            const isLastActionGroup = actionGroupIndex === actions.length - 1;
                            if (actionGroup.actions.length == 0) return <React.Fragment key={actionGroup.key}></React.Fragment>;
                            return (
                                <React.Fragment key={actionGroup.key}>
                                    <Stack horizontal verticalAlign="center" horizontalAlign="center" tokens={{ childrenGap: 8 }}>
                                        {actionGroup.actions.map((action) => (
                                            <CallActionButton
                                                key={action.key}
                                                action={action}
                                                onPressed={(a: ICallAction) => onActionPressed(a)}
                                                micIsMuted={micIsMuted}
                                                cameraIsMuted={cameraIsMuted}
                                                sharingIsOn={sharingIsOn}
                                                statsAreVisible={statsAreVisible}
                                                isLocalUserHandRaised={isLocalUserHandRaised}
                                                isChatVisible={isChatVisible}
                                            />
                                        ))}
                                    </Stack>
                                    {!isLastActionGroup && <CallActionBarDivider vertical={false} />}
                                </React.Fragment>
                            );
                        })
                    )}
                </Stack>

                {/* VM-Set topic for smaller devices*/}
                {(smallDevice || mediumDevice || smallLaptop) &&
                    (showTopic || topic.length > 0) &&
                    window.location.pathname.includes('rooms') &&
                    !window.location.pathname.includes('personal-') && (
                        <Stack
                            style={{
                                backgroundColor: `${branding?.orgColors[0]}`,
                                color: bgColor ? getTextColorForBackground(bgColor) : 'black',
                                cursor: 'pointer',
                                padding: '5px 10px',
                                width: '100%',
                                height: 'auto'
                            }}
                            horizontalAlign="center"
                            verticalAlign="center"
                            onClick={
                                !smallLaptop
                                    ? () => {
                                          if (!(localProfile?.type === 'visitor' || localProfile?.type === 'auto-confirmed-visitor')) {
                                              setToggleSetTopicDialog(false);
                                          }
                                      }
                                    : undefined
                            }
                        >
                            <Text
                                variant="medium"
                                style={{
                                    fontWeight: 'bold',
                                    width: '95%',
                                    textAlign: 'center',
                                    color: bgColor ? getTextColorForBackground(bgColor) : 'white'
                                }}
                            >
                                {topic}
                            </Text>
                        </Stack>
                    )}
                {/* VM-Set topic rendering the dialog box*/}
                <SetTopicDialog />
            </Stack>
        </React.Fragment>
    );
};

export default CallActionBarView;

export interface ICallActionBarDividerProps {
    vertical?: boolean;
}
export const CallActionBarDivider: React.FC<ICallActionBarDividerProps> = ({ vertical }) => {
    const theme = getTheme();

    const dividerStyles = mergeStyles([
        {
            backgroundColor: theme.palette.neutralQuaternary
        },
        vertical
            ? {
                  height: 1,
                  minHeight: 1,
                  width: '100%'
              }
            : {
                  height: '100%',
                  minHeight: '100%',
                  width: 1
              }
    ]);

    return <div className={dividerStyles} />;
};
