import { PrimaryButton, Stack, Text } from '@fluentui/react';
import React from 'react';
import campusLogo from 'media/img/ivcampus-logo.svg';

const SamlAuthError: React.FC = () => {
    return (
        <Stack
            style={{
                backgroundColor: '#e4e4e3',
                flexDirection: 'column',
                minHeight: '100vh',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Stack
                style={{
                    marginBottom: 53,
                    width: 375,
                    height: 73
                }}
            >
                <img src={campusLogo} alt="logo" />
            </Stack>

            <Stack
                style={{
                    height: 300,
                    width: 375,
                    backgroundColor: '#fff',
                    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                    alignItems: 'center',
                    padding: 20
                }}
            >
                <Text variant="xLarge" style={{ marginBottom: 20, textAlign: 'center' }}>
                    SAML-Authentifizierungsfehler
                </Text>
                <Text style={{ marginBottom: 40, textAlign: 'center' }}>
                    Es ist ein Fehler bei der SAML-Authentifizierung aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an Ihren Administrator.
                </Text>
                <PrimaryButton
                    text="Zurück zur Login-Seite"
                    onClick={() => {
                        window.opener.postMessage('samlLoginError', '*');
                        window.close();
                    }}
                />
            </Stack>
        </Stack>
    );
};

export default SamlAuthError;
