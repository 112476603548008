import { ParticipantInfo } from 'features/Rooms/RoomView/comps/Attachments/YoutubeVideoAttachmentManager';
import { atom } from 'recoil';

export const aLinkAttachmentModalBox = atom<boolean>({ key: 'aLinkAttachmentModalBox', default: true });
export const aCreatedLinkAttachment = atom<any>({ key: 'aCreatedLinkAttachment', default: false });
export const aLinkAttachmentModalValue = atom<any>({ key: 'aLinkAttachmentModalValue', default: '' });
export const aLinkAttachmentItemId = atom<any>({ key: 'aLinkAttachmentItemId', default: '' });
export const aLinkAttachmentItemIsPinned = atom<boolean>({ key: 'aLinkAttachmentItemIsPinned', default: false });
// export const aIsVideoPlaying= atom<boolean>({ key: 'aIsVideoPlaying', default: false });
export const aParticipantsList = atom<ParticipantInfo[]>({ key: 'aParticipantsList', default: [] });
export const aPlayingVideoId = atom<string>({ key: 'aPlayingVideoId', default: '' });
export const aPlayingVideoOwnerId = atom<string>({ key: 'aPlayingVideoOwnerId', default: '' });
export const aIsLocalUserVideoOwner = atom<boolean>({ key: 'aIsLocalUserVideoOwner', default: false });
export const aPreviousVideoOwnerId = atom<string>({ key: 'aPreviousVideoOwnerId', default: '' });
export const aPreferredLanguage = atom<string>({ key: 'aPreferredLanguage', default: 'en' });
