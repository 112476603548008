import { ICallAction } from 'features/Rooms/RoomView/CallActions';
import React from 'react';
import CallActionButtonView from './CallActionButton.view';

export interface ICallActionButtonProps {
    action: ICallAction;
    onPressed: (a: ICallAction) => void;
    cameraIsMuted?: boolean;
    micIsMuted?: boolean;
    sharingIsOn?: boolean;
    statsAreVisible?: boolean;
    isLocalUserHandRaised?: boolean;
    isChatVisible?: boolean;
}

const CallActionButton: React.FC<ICallActionButtonProps> = (props) => {
    return (
        <CallActionButtonView
            {...{
                ...{
                    action: props.action,
                    onPressed: props.onPressed,
                    cameraIsMuted: props.cameraIsMuted,
                    micIsMuted: props.micIsMuted,
                    sharingIsOn: props.sharingIsOn,
                    statsAreVisible: props.statsAreVisible,
                    isLocalUserHandRaised: props.isLocalUserHandRaised,
                    isChatVisible: props.isChatVisible
                }
            }}
        ></CallActionButtonView>
    );
};
export default CallActionButton;
