import { PrimaryButton, Stack, Text } from '@fluentui/react';
import React, { useEffect } from 'react';
import hskLogo from 'media/img/hsk-logo.png';
import { useParams } from 'react-router-dom';
import { idpService } from 'services/api/identity-provider.service';
import jwt from 'jwt-decode';

const SamlAuth: React.FC = () => {
    const { samlProvider } = useParams<{ samlProvider: string }>();

    useEffect(() => {
        // Listen for messages from the popup window
        const handleMessage = (event: MessageEvent) => {
            if (event.data === 'samlLoginSuccess') {
                // Handle successful login
                console.log('SAML login successful');
                const token = localStorage.getItem('samlToken');

                if (token && samlProvider) {
                    idpService.authUserSAML(token, samlProvider).then(() => {
                        window.location.href = '/welcome';
                        localStorage.removeItem('samlToken');
                    });

                    // fetch('http://localhost:4000/v1/auth/saml/authenticate', {
                    //     method: 'POST',
                    //     headers: {
                    //         'Content-Type': 'application/json'
                    //     },
                    //     body: JSON.stringify({
                    //         samlToken: token,
                    //         samlConfigId: samlProvider
                    //     })
                    // })
                    //     .then((response) => {
                    //         if (response.ok) {
                    //             return response.json();
                    //         }
                    //         throw new Error('SAML auth failed');
                    //     })
                    //     .then((data) => {
                    //         localStorage.setItem('ivAccessToken', data.token);
                    //         window.location.href = '/welcome';
                    //     })
                    //     .catch((error) => {
                    //         console.error('SAML auth error:', error);
                    //     });
                } else {
                    console.log('login error');
                }
            }

            if (event.data === 'samlLoginError') {
                console.log('SAML login error');
                window.location.href = `/sso/${samlProvider}`;
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, []);

    const validateAccessToken = () => {
        const accessToken = localStorage.getItem('ivAccessToken');
        if (!accessToken) return false;

        const userData = jwt<any>(accessToken);
        if (!userData || !userData.exp || userData.exp < Date.now() / 1000 || !userData.cert_thumbprint || samlProvider !== userData.saml_config_id)
            return false;

        return true;
    };

    const handleSamlLogin = () => {
        const isAccessTokenValid = validateAccessToken();

        if (isAccessTokenValid) {
            window.location.href = '/welcome';
        } else {
            const width = 500;
            const height = 600;
            const left = window.screenX + (window.innerWidth - width) / 2;
            const top = window.screenY + (window.innerHeight - height) / 2;

            // Get base URL from env and replace the provider
            const samlAuthUrl = process.env.REACT_APP_SAML_AUTH_URL?.replace('client-sso', samlProvider || '');

            // Open a new window for SAML authentication
            const samlWindow = window.open(samlAuthUrl, 'SAML Login', `width=${width},height=${height},left=${left},top=${top}`);

            // Optional: Check if popup was blocked
            if (!samlWindow) {
                alert('Please enable popups for this site');
            }
        }
    };

    return (
        <Stack
            style={{
                backgroundColor: '#e4e4e3',
                flexDirection: 'column',
                minHeight: '100vh',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Stack
                style={{
                    width: 375,
                    backgroundColor: '#fff',
                    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                    padding: 20,
                    borderRadius: 4,
                    maxWidth: '90%'
                }}
            >
                <Stack
                    style={{
                        marginBottom: 30,
                        width: '100%',
                        height: 'auto'
                    }}
                >
                    <img
                        src={hskLogo}
                        alt="logo"
                        style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'contain'
                        }}
                    />
                </Stack>

                <Text
                    style={{
                        marginBottom: 30,
                        textAlign: 'center',
                        color: '#666'
                    }}
                >
                    In das digitale Verwaltungszentrum des Hochsauerlandkreises
                </Text>

                <PrimaryButton
                    text="EINTRETEN"
                    onClick={handleSamlLogin}
                    styles={{
                        root: {
                            backgroundColor: '#008751',
                            width: '100%',
                            height: 40,
                            marginBottom: 20
                        }
                    }}
                />

                <Text
                    style={{
                        textAlign: 'center',
                        color: '#666',
                        fontSize: 14,
                        cursor: 'pointer',
                        textDecoration: 'underline'
                    }}
                    onClick={() => (window.location.href = '/login')}
                >
                    oder mit Magic Link anmelden
                </Text>
            </Stack>
        </Stack>
    );
};

export default SamlAuth;
