import { ConfigOptions, InterfaceConfigOptions } from '@ivicos/react-jitsi/dist/types';
import { getJitsiConfig, getJitsiInterfaceConfig } from 'features/Rooms/RoomView/config/jitsi';
import { ISpeakerStatsSpeaker } from 'features/Rooms/SpeakerStats/children/Data';
import { IResource } from 'kits/apiKit3/legacy';
import { useEvent } from 'kits/eventKit';
import React, { useEffect, useState } from 'react';
import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';
import { aHandRaiseButtonDisabled } from 'shared-state/directory/atoms';
import { useSelectedAttachment } from 'shared-state/location/hook';
import { useStatePersist } from 'use-state-persist';
import { hardcodedAddOns } from '../addons';
import { ICallAction, ICallActionKey, useCallActions } from '../CallActions';
import { useCallState } from './callstate';
import { aIsBackgroundDialogOpen } from '../atom';
import { IJitsiMeetExternalApi } from '@jitsi/react-sdk/lib/types/';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { aPreferredLanguage } from 'features/LinkAttachmentModalBox/atom';

export interface IRoomCallControllerStateProps {
    jitsiAPI: any;
    jitsiConfig: ConfigOptions;
    interfaceConfig: InterfaceConfigOptions;
    selectedAttachment:
        | {
              addOnId: string;
              ref: string;
              description: string;
          }
        | undefined;
    setSelectedAttachment: SetterOrUpdater<
        | {
              addOnId: string;
              ref: string;
              description: string;
          }
        | undefined
    >;
    addOnIsVisible: boolean;
    eventListeners: any[];
    activeAddOn: string | undefined;
    cameraIsMuted: boolean;
    micIsMuted: boolean;
    sharingIsOn: boolean;
    isConnected: boolean;
    tileViewIsEnabled: boolean;
    encryptionIsEnabled: boolean;
    stats: ISpeakerStatsSpeaker[];
    statsAreVisible: boolean;
    executeCommand: (command: string, data?: any) => void;
    showDeviceSettings: boolean;
    actionBarActions: {
        key: string;
        actions: ICallAction[];
    }[];
    isVideoHidden: boolean;
    setIsVideoHidden: React.Dispatch<React.SetStateAction<boolean>>;
    isDragging: boolean;
    setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
    movementDirection: {
        x: number;
        y: number;
    };
    setMovementDirection: React.Dispatch<
        React.SetStateAction<{
            x: number;
            y: number;
        }>
    >;
    dragStartPosition: {
        x: number;
        y: number;
    };
    setDragStartPosition: React.Dispatch<
        React.SetStateAction<{
            x: number;
            y: number;
        }>
    >;
    translateToHideOrShow: () => {
        x: number;
        y: number;
    };
    jitsiShowHideInnerContainerOpacity: number;
    activeAddOnData:
        | {
              id: string;
              type: string;
              attributes: {
                  urlTemplate: string;
                  iconUrl: string;
                  displayName: string;
                  description: string;
              };
              relationships: {};
          }
        | undefined;
    extractPositionFromEvent: (event: React.MouseEvent) => {
        x: number;
        y: number;
    };
    isLocalUserHandRaised: boolean;
    profileImageURL: string | undefined;
    handleJitsiMeetExternalAPILoad: (api: IJitsiMeetExternalApi) => void;
    userEmailAddress: string;
    isChatVisible: boolean;
}

export interface IRoomCallEventListener {
    eventName: string;
    handler: (e: any) => void;
}

const useRoomCallController: (
    actions: Array<Array<ICallActionKey>>,
    profileImageURL: string | undefined,
    callEventListeners: Array<IRoomCallEventListener> | undefined,
    muteOnJoin: 'audio' | 'both' | 'video' | undefined,
    room: IResource | undefined,
    onAPI: ((api: any) => void) | undefined,
    roomName: string,
    onAddOnDataRecieved?: ((addonId: string, data: any) => void) | undefined,
    userIsAloneInOwnPersonalRoom?: boolean
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
) => IRoomCallControllerStateProps = (actions, profileImageURL, callEventListeners, muteOnJoin, room, onAPI, roomName, userIsAloneInOwnPersonalRoom) => {
    const [jitsiAPI, setJitsiAPI] = useState<any>();
    const [checkForInstanceToggling, setCheckForInstanceToggling] = useState(false);

    const [isBackgroundDialogOpen, setIsBackgroundDialogOpen] = useRecoilState<any>(aIsBackgroundDialogOpen);

    const [identityClaims] = useIdentityClaims();

    const userEmailAddress = identityClaims && identityClaims.upn ? identityClaims.upn : 'unknown.email@ivicos-campus.app';

    const preferredLanguage = useRecoilValue(aPreferredLanguage);

    useEffect(() => {
        if (jitsiAPI !== undefined) {
            jitsiAPI.executeCommand('setUILanguage', preferredLanguage);

            if (isBackgroundDialogOpen) {
                window.location.href = '#';
                jitsiAPI.executeCommand('toggleVirtualBackgroundDialog');
            }
        }
        setIsBackgroundDialogOpen(false);
    }, [isBackgroundDialogOpen, jitsiAPI, preferredLanguage]);

    const jitsiConfig = getJitsiConfig(muteOnJoin);

    // const interfaceConfig: InterfaceConfigOptions = getJitsiInterfaceConfig(roomName.includes('personal-'));
    const interfaceConfig = getJitsiInterfaceConfig();

    const [overlayURI] = useStatePersist<string | undefined>('@exp:overlay');
    const [overlayIsTransparent] = useStatePersist<boolean>('@exp:overlay:istransparent');

    const updateOverlay = () => {
        typeof overlayURI != 'undefined' && typeof overlayIsTransparent != 'undefined' && setOverlay(overlayURI || '', overlayIsTransparent);
    };

    const setOverlay = (url: string, isTransparent: boolean) => {
        jitsiAPI.setForegroundOverlay(url || '', '', isTransparent ? 'fusion' : 'circle');
    };
    const overlayListener = {
        eventName: 'videoMuteStatusChanged',
        handler: (e: any) => {
            if (!e.muted) updateOverlay();
        }
    };

    const [selectedAttachment, setSelectedAttachment] = useSelectedAttachment();
    const addOnIsVisible = selectedAttachment ? true : false;
    // CallState
    const {
        eventListeners,
        activeAddOn,
        cameraIsMuted,
        micIsMuted,
        sharingIsOn,
        isConnected,
        tileViewIsEnabled,
        encryptionIsEnabled,
        stats,
        statsAreVisible,
        executeCommand,
        showDeviceSettings,
        isLocalUserHandRaised,
        isChatVisible
    } = useCallState(roomName, room, jitsiAPI, [...(callEventListeners || []), ...[overlayListener]]);

    const shouldDisableButton = cameraIsMuted && micIsMuted && userIsAloneInOwnPersonalRoom && !sharingIsOn;

    // Actions
    const callActions = useCallActions({
        tileViewIsEnabled,
        cameraIsMuted,
        sharingIsOn,
        micIsMuted,
        encryptionIsEnabled,
        statsAreVisible,
        addOnIsVisible,
        activeAddOn,
        shouldDisableButton,
        isLocalUserHandRaised,
        isChatVisible
    });

    const actionBarActions = actions.map((actionGroup, actionGroupIndex) => {
        const groupActions: Array<ICallAction> = actionGroup.map((action) => {
            return callActions[action];
        });

        return {
            key: 'action-group-' + actionGroupIndex,
            actions: groupActions.filter((a) => a != undefined)
        };
    });

    useEffect(() => {
        const api = jitsiAPI;

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        if (!api) return () => {};

        onAPI && onAPI(api);

        updateOverlay();

        eventListeners.forEach((listener: any) => {
            api.addEventListener(listener.eventName, listener.handler);
        });

        api.addEventListener('notificationTriggered', ({ title }) => {
            if (title === 'notify.mutedRemotelyTitle') {
                //! Toggle audio twice to fix the issue where the API reports the audio as muted when it is not
                executeCommand('toggleAudio');
                executeCommand('toggleAudio');
            }
        });

        setTimeout(() => {
            setCheckForInstanceToggling(true);
        }, 3500);

        return () => {
            eventListeners.forEach((listener: any) => {
                api.removeEventListener(listener.eventName, listener.handler);
            });
            api.removeEventListener('notificationTriggered');
        };
    }, [jitsiAPI, overlayURI, overlayIsTransparent]);

    useEffect(() => {
        if (!jitsiAPI || !checkForInstanceToggling) return;

        jitsiAPI
            .isAudioMuted()
            .then((muted) => {
                if (muted !== micIsMuted) {
                    //! Toggle audio twice to fix the issue where the API reports the audio as muted when it is not
                    executeCommand('toggleAudio');
                    executeCommand('toggleAudio');
                }
            })
            .catch((error) => {
                console.error('Error checking audio mute status:', error);
            });

        setTimeout(() => {
            setCheckForInstanceToggling(false);
        }, 1000);
    }, [jitsiAPI, checkForInstanceToggling]);

    const [, setHandRaiseButtonDisabled] = useRecoilState<boolean>(aHandRaiseButtonDisabled);

    const handleJitsiMeetExternalAPILoad = (api: IJitsiMeetExternalApi) => {
        console.log('API loaded (JitsiMeetExternalApi)');
        api.executeCommand('subject', ' ');
        setJitsiAPI(api);
        setHandRaiseButtonDisabled(false);
    };

    const [isVideoHidden, setIsVideoHidden] = useState(false);

    const [isDragging, setIsDragging] = useState(false);
    const [movementDirection, setMovementDirection] = useState({ x: 0, y: 0 });
    const [dragStartPosition, setDragStartPosition] = useState({ x: 0, y: 0 });

    const translateToHideOrShow = React.useCallback(() => {
        return isVideoHidden ? { x: -409, y: 0 } : movementDirection;
    }, [isVideoHidden, movementDirection]);

    const [jitsiShowHideInnerContainerOpacity, setJitsiShowHideInnerContainerOpacity] = useState(0);

    const activeAddOnData = hardcodedAddOns.find((a) => a.id == selectedAttachment?.addOnId);

    useEvent('disable-camera', () => {
        cameraIsMuted == false ? executeCommand('toggleVideo') : null;
    });

    useEvent('disable-mic', () => {
        if (micIsMuted == false && muteOnJoin == 'audio') {
            return null;
        }
        micIsMuted == false ? executeCommand('toggleAudio') : null;
    });

    const extractPositionFromEvent = React.useCallback((event: React.MouseEvent) => {
        const positionX = event.screenX;
        const positionY = event.screenY;
        return { x: positionX, y: positionY };
    }, []);

    useEffect(() => {
        let timer;
        if (!selectedAttachment) setJitsiShowHideInnerContainerOpacity(0);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        else timer = setTimeout(() => setJitsiShowHideInnerContainerOpacity(1), 500);
    }, [selectedAttachment]);

    return {
        actionBarActions,
        activeAddOn,
        activeAddOnData,
        addOnIsVisible,
        cameraIsMuted,
        dragStartPosition,
        encryptionIsEnabled,
        eventListeners,
        executeCommand,
        extractPositionFromEvent,
        handleJitsiMeetExternalAPILoad,
        interfaceConfig,
        isChatVisible,
        isConnected,
        isDragging,
        isLocalUserHandRaised,
        isVideoHidden,
        jitsiAPI,
        jitsiConfig,
        jitsiShowHideInnerContainerOpacity,
        micIsMuted,
        movementDirection,
        profileImageURL,
        selectedAttachment,
        setDragStartPosition,
        setIsDragging,
        setIsVideoHidden,
        setMovementDirection,
        setSelectedAttachment,
        sharingIsOn,
        showDeviceSettings,
        stats,
        statsAreVisible,
        tileViewIsEnabled,
        translateToHideOrShow,
        userEmailAddress
    };
};

export default useRoomCallController;
