import React, { useState } from 'react';
import { Stack } from '@fluentui/react';

import { useIdentityClaims } from 'shared-state/identity/hooks';
import StatefulButton from 'components/StatefulButton/StatefulButton';
import IvicosStrings from 'kits/language/stringKit';
import { IC } from 'components/SVGIcon';
import SvgArchive from 'kits/IconKit2/Archive';
import Save from 'kits/IconKit2/Save';
import { IRoomResource } from 'kits/apiKit3/legacy';
import { SetterOrUpdater } from 'recoil';
import { sendEvent } from 'kits/eventKit';
import { useEmittingEvents } from 'services/socket-connection/socket-adapters/event-actions/emittingEvents';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useConfirmationPanelManager = (
    room: IRoomResource | undefined,
    setRoom: SetterOrUpdater<IRoomResource | undefined>,
    setRoomSettingsRoomId: SetterOrUpdater<string>,
    onRemoveRoom: (roomId: string) => Promise<boolean>,
    onUpdateRoom: (roomId: string, changes: any) => Promise<any | undefined>,
    isTheUserInsideTheRoom: boolean,
    hasFormChanged: () => boolean,
    changes: any,
    resetChanges: () => void,
    setActiveTab: React.Dispatch<React.SetStateAction<string>>
) => {
    const [idClaims] = useIdentityClaims();

    const [confirmationTarget, setConfirmationTarget] = useState<'dismiss' | 'delete' | 'none'>('none');
    const { updateRoomData } = useEmittingEvents();

    const isUserAllowedToArchiveRoom: boolean =
        (!Array.isArray(room?.attributes?.whitelist) && (idClaims?.roles.includes('manager') || idClaims?.roles.includes('owner'))) ||
        (Array.isArray(room?.attributes?.whitelist) && room?.attributes?.private && room?.attributes?.creatorId === idClaims?.uid);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onDismiss = (_changed: boolean) => {
        setRoomSettingsRoomId('');
    };

    const onConfirm = (target: string) => {
        if (target == 'dismiss') {
            setConfirmationTarget('none');
            onDismiss(false);
        }
        if (target == 'delete') {
            const _roomId = room?.id;
            _roomId &&
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onRemoveRoom(_roomId).then((_success: any) => {
                    updateRoomData('deletion', undefined, _roomId);
                    setConfirmationTarget('none');
                });
            setRoom(undefined);
            onDismiss(true);
        }

        setActiveTab('General');
    };

    const onPanelDismiss = () => {
        if (hasFormChanged()) return setConfirmationTarget('dismiss');
        onDismiss && onDismiss(hasFormChanged());
        setActiveTab('General');
    };

    const onRenderPanelFooterContent = () => {
        return (
            <Stack horizontal horizontalAlign={isUserAllowedToArchiveRoom ? 'space-between' : 'end'} verticalAlign="center">
                {isUserAllowedToArchiveRoom && (
                    <Stack style={{ marginRight: 10 }}>
                        <StatefulButton
                            disabled={isTheUserInsideTheRoom}
                            onRenderIcon={() => (
                                <IC size={24} variant="red">
                                    <SvgArchive />
                                </IC>
                            )}
                            onClick={() => {
                                if (!room) return;
                                setConfirmationTarget('delete');
                            }}
                            text={IvicosStrings.deleteRoomActionTitle}
                            loadingText={IvicosStrings.deleteRoomActionLoadingTitle}
                            successText={IvicosStrings.deleteRoomActionConfirmationTitle}
                        ></StatefulButton>
                    </Stack>
                )}

                <StatefulButton
                    variant="primary"
                    text={IvicosStrings.save}
                    loadingText={IvicosStrings.savingActionLoadingTitle}
                    successText={IvicosStrings.savingActionSuccessTitle}
                    onRenderIcon={() => <Save />}
                    onClick={(_e, setButtonState) => {
                        if (!room) return;

                        if (hasFormChanged()) {
                            setButtonState(1);
                            onUpdateRoom &&
                                onUpdateRoom(room.id, changes).then((r) => {
                                    if (r.data) updateRoomData('update', r.data);
                                    // storing room id in localStorage for audio only rooms
                                    const idValue = r.data.id;
                                    if (r.data.id && r.data.attributes.audioOnly) {
                                        sendEvent('disable-camera', {});
                                        const noVideoRoomId: any = room?.id;
                                        sessionStorage.setItem(`noVideo-${idValue}`, noVideoRoomId);
                                    }
                                    if (!r.data.attributes.audioOnly) {
                                        sessionStorage.removeItem(`noVideo-${idValue}`);
                                    }
                                    setRoom((prevRoom: IRoomResource | undefined) => {
                                        if (!prevRoom) return prevRoom;

                                        const newRoom = { ...prevRoom, ...{ attributes: r.data.attributes } };

                                        return newRoom;
                                    });

                                    resetChanges();
                                    setButtonState(2);

                                    onDismiss && onDismiss(hasFormChanged());
                                    setActiveTab('General');
                                    return r;
                                });
                            return;
                        }
                        onDismiss && onDismiss(hasFormChanged());
                        setActiveTab('General');
                    }}
                />
            </Stack>
        );
    };
    const getConfirmationTitle = () => (confirmationTarget == 'delete' ? IvicosStrings.deleteQuestion1 : IvicosStrings.deleteQuestion2);

    const getConfirmationDesc = () => (confirmationTarget == 'delete' ? IvicosStrings.deleteQuestion3 : IvicosStrings.deleteQuestion4);

    return [confirmationTarget, setConfirmationTarget, getConfirmationTitle, getConfirmationDesc, onRenderPanelFooterContent, onConfirm, onPanelDismiss];
};

export default useConfirmationPanelManager;
